.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  text-transform: uppercase;
}


label {
  display: inline-block;
  width: 100px;
}

.reset{
  position: absolute;
  margin: 10px;
  padding: 5px 5px 2px 2px;
  top: 80px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.reset:hover{
  cursor: pointer
}

.reset:active{
  border-style: outset;
}

input {
  margin-left: 20px;
  width: 160px;
}

.nav{
  position: relative;
  margin: 8px;
  padding: 4px;
}

.tooltip {
  position: absolute;
  margin: 8px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 10px;
  z-index: 9;
  pointer-events: none;
}